import React from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconInquiry,
  Heading,
  FieldSelect,
} from '../../../components';

import css from './ComplimentaryRequestForm.module.css';

const ErrorMessage = props => {
  const { error } = props;
  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="ComplimentaryRequestForm.sendInquiryErrorNoProcess" />
      ) : (
        <FormattedMessage id="ComplimentaryRequestForm.sendComplimentaryRequestError" />
      )}
    </p>
  ) : null;
};

const ComplimentaryRequestFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendComplimentaryRequestError,
        ownEventlistings,
      } = fieldRenderProps;

      const hasOwnEventListings = ownEventlistings.length > 0;

      const messageLabel = intl.formatMessage(
        {
          id: 'ComplimentaryRequestForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'ComplimentaryRequestForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'ComplimentaryRequestForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = !hasOwnEventListings || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconInquiry className={css.icon} />
          <Heading as="h2" rootClassName={css.heading}>
            <FormattedMessage id="ComplimentaryRequestForm.heading" values={{ listingTitle }} />
          </Heading>

          {hasOwnEventListings && (
            <FieldSelect
              id={`${formId}.eventId`}
              className={css.quantityField}
              name="eventId"
              disabled={!hasOwnEventListings}
              label={intl.formatMessage({ id: 'MediaPartnerConnectionForm.selectEvent' })}
              validate={validators.required(
                intl.formatMessage({ id: 'MediaPartnerConnectionForm.eventRequiredMessage' })
              )}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'MediaPartnerConnectionForm.selectEventOption' })}
              </option>
              {ownEventlistings.map(listing => (
                <option key={listing.id.uuid} value={listing.id.uuid}>
                  {listing.attributes.title}
                </option>
              ))}
            </FieldSelect>
          )}

          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
          <div className={submitButtonWrapperClassName}>
            <ErrorMessage error={sendComplimentaryRequestError} />
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="ComplimentaryRequestForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ComplimentaryRequestFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendComplimentaryRequestError: null,
};

ComplimentaryRequestFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendComplimentaryRequestError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  ownEventlistings: arrayOf(propTypes.listing).isRequired,
};

const ComplimentaryRequestForm = compose(injectIntl)(ComplimentaryRequestFormComponent);

ComplimentaryRequestForm.displayName = 'ComplimentaryRequestForm';

export default ComplimentaryRequestForm;
