import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, Modal, UserCard } from '../../components';
import InquiryForm from './InquiryForm/InquiryForm';
import ComplimentaryRequestForm from './ComplimentaryRequestForm/ComplimentaryRequestForm';

import css from './ListingPage.module.css';

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    currentUser,
    onManageDisableScrolling,
    // onComplimentaryConnectUser,
    isComplimentaryRequestModalOpen,
    onCloseComplimentaryRequestModal,
    sendComplimentaryRequestError,
    sendComplimentaryRequestInProgress,
    onSubmitComplimentaryRequest,
    ownEventlistings,
    influencerPackages,
    event,
  } = props;

  if (!listing.author) {
    return null;
  }
  const inquiryFormInitialValues = {
    influencerPackages:
      props.influencerPackages instanceof Array && props.influencerPackages.map(decodeURI),
    event,
  };

  return (
    <div id="author" className={css.sectionAuthor}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </Heading>
      <UserCard user={listing.author} currentUser={currentUser} onContactUser={onContactUser} />
      <Modal
        id="ListingPage.inquiry"
        contentClassName={css.inquiryModalContent}
        isOpen={isInquiryModalOpen}
        onClose={onCloseInquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InquiryForm
          className={css.inquiryForm}
          submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendInquiryError={sendInquiryError}
          onSubmit={onSubmitInquiry}
          inProgress={sendInquiryInProgress}
          influencerPackages={influencerPackages}
          initialValues={inquiryFormInitialValues}
          event={event}
        />
      </Modal>
      <Modal
        id="ListingPage.complimentaryRequest"
        contentClassName={css.complimentaryRequestModalContent}
        isOpen={isComplimentaryRequestModalOpen}
        onClose={onCloseComplimentaryRequestModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ComplimentaryRequestForm
          className={css.complimentaryRequestForm}
          submitButtonWrapperClassName={css.complimentaryRequestSubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendComplimentaryRequestError={sendComplimentaryRequestError}
          onSubmit={onSubmitComplimentaryRequest}
          inProgress={sendComplimentaryRequestInProgress}
          ownEventlistings={ownEventlistings}
        />
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
